import Vue from 'vue'
import utils from '@/helpers/utilidades'

const helper = {
	install(Vue, options) {
		Vue.prototype.$helper = {
			...utils
		}
	}
}

Vue.use(helper);