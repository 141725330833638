import Vue from 'vue'

const paginator = {
  install(Vue, options) {
    Vue.prototype.$paginator = {
      paginate_data: function(data, pagina, por_pagina, item_num_order, key_order) {
        if (!key_order)
          key_order = 'id';

        if (!item_num_order)
          item_num_order = 'asc';

        let datos_mostrar = [];

        let inicio = pagina == 1 ? 0 : ((pagina-1) * por_pagina);
        let final = pagina < this.total_pages(data, por_pagina) ? ((inicio+por_pagina)-1) : (data.length-1);

        Vue.prototype.$log.info('inicio', inicio);
        let total_items = item_num_order == 'desc' ? (data.length - inicio) : (inicio+1);

        data.sort((a, b) => {
          return item_num_order == 'asc' ? (a[key_order] - b[key_order]) : b[key_order] - a[key_order];
        });

        for(let i=inicio; i<=final; i++) {
          if (typeof data[i].num_item_pag == 'undefined') {
            data[i].num_item_pag = total_items;
            if (item_num_order == 'desc')
              total_items--;
            else
              total_items++;
          }

          datos_mostrar.push(data[i]);
        }

        return datos_mostrar;
      }

      ,total_pages(data,por_pagina) {
        return Math.ceil(data.length / por_pagina);
      }

      ,paginate_navigation(data, pagina, por_pagina, a_mostrar) {
        if (typeof a_mostrar == 'undefined')
          a_mostrar = 3;

        let paginas = [];

        let total_paginas = this.total_pages(data, por_pagina);
        console.log('num_rows', data.length);
        console.log('por_pagina', por_pagina);
        console.log('total_paginas', total_paginas);

        let inicio = pagina == 1 ? 1 : (pagina > a_mostrar ? (pagina - a_mostrar) :  1);
        let final = pagina == total_paginas ? total_paginas : (pagina < (total_paginas - a_mostrar) ? (parseInt(pagina)+parseInt(a_mostrar)) : parseInt(total_paginas));
        console.log('inicio', inicio);
        console.log('final', final);
        for(let i=inicio; i<=final; i++) {
          paginas.push(i);
        }

        return paginas;
      }

      ,paginate_navigation_links(data, pagina, por_pagina, a_mostrar, id_contenedor, identificador, pagina_anterior, pagina_siguiente, saltar_pagina) {
        if (typeof a_mostrar == 'undefined')
          a_mostrar = 3;

        if (typeof identificador != 'string')
          throw new Error('El identificador debe ser string');

        let total_paginas = this.total_pages(data, por_pagina);
        let paginas = this.paginate_navigation(data, pagina, por_pagina, a_mostrar);

        let links = '';
        let config = {data, pagina, por_pagina, a_mostrar, identificador};
        
        if (total_paginas > 1) {
          links += '<nav><ul class="pagination pagination-sm justify-content-end">';
          links += '<li class="page-item"><a id="navigation_'+identificador+'_prev" class="page-link" href="#">&laquo;</a></li>';

          paginas.forEach(num_pagina => {
            links += '<li class="page-item'+(num_pagina == pagina ? ' active' : '')+'">'
                  + '<a class="page-link navigation_'+identificador+'_page" href="#" data-page="'+num_pagina+'">' + num_pagina + '</a></li>';
          })

          links += '<li class="page-item"><a id="navigation_'+identificador+'_next" class="page-link" href="#">&raquo;</a></li>';

          links += '</ul></nav>';

          let contenedor = document.querySelector('#'+id_contenedor);
  
          if (!contenedor)
            throw new Error('No se encontró contenedor de navegación');
  
          contenedor.innerHTML = links;

          if (typeof pagina_anterior != 'function')
            throw new Error('Se necesita definir el callback para eventos de la navegación');
          if (typeof pagina_siguiente != 'function')
            throw new Error('Se necesita definir el callback para eventos de la navegación');
          if (typeof saltar_pagina != 'function')
            throw new Error('Se necesita definir el callback para eventos de la navegación');

          let prev_btn = document.querySelector('#navigation_'+identificador+'_prev');
          let next_btn = document.querySelector('#navigation_'+identificador+'_next');
          let page_btns = document.querySelectorAll('.navigation_'+identificador+'_page');

          if (prev_btn) {
            prev_btn.removeEventListener('click', pagina_anterior);
            prev_btn.addEventListener('click', pagina_anterior);
          }

          if (next_btn) {
            next_btn.removeEventListener('click', pagina_siguiente);
            next_btn.addEventListener('click', pagina_siguiente);
          }

          if (page_btns) {
            page_btns.forEach(btn => {
              btn.removeEventListener('click', saltar_pagina);
              btn.addEventListener('click', saltar_pagina);
            })
          }
        }
      }
    }
  }
}

Vue.use(paginator);