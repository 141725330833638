import Vue from 'vue'
import store from '@/store'

const Auth = {
	install(Vue, options) {
		Vue.prototype.$auth = {
			isAuth() {
				return store.getters.isAuth;
			}

			,getAuth() {
				return store.getters.getAuth;
			}

			,getUser() {
        let user = store.getters.getUser;
        user.getContact = function(type,validate) {
          if (typeof(validate) == 'undefined')
            validate = false;

          let user = store.getters.getUser;

          let contacto = null;

          user.contactos.forEach(contact => {
            if (contact.tipo == type) {
              if (!validate) {
                contacto = contact;
              }else {
                if (contact.validado == 1) {
                  contacto = contact
                }
              }
            }
          })

          return contacto;
        }

				return user;
			}

      ,getPermissions() {
          return store.getters.getUser.permisos;
      }

      ,getRoles() {
          return store.getters.getUser.roles;
      }

      ,getApp() {
          return store.getters.getUser.aplicaciones;
      }

      ,findApp(app) {
      	let apps = this.getApp();

      	let encontrado = false;
      	for(let i=0; i<apps.length; i++) {
      		if (this.clear_name(apps[i].nombre) == this.clear_name(app)) {
      			encontrado = true;
      			i=apps.length;
      		}
      	}

      	return encontrado;
      }

      ,isAdmin(app) {
      	return this.can(app,'*');
      }

      ,can(app, permisos_validacion, visible) {
      	let permitido = false;

      	if (typeof(visible) == 'undefined')
      		visible = true;

      	if (!Array.isArray(permisos_validacion)) {
      		let tmp = permisos_validacion;
      		permisos_validacion = [];
      		permisos_validacion.push(tmp);
      	}
      	// Vue.prototype.$log.info('permisos', permisos_validacion);

      	if (store.getters.getUser) {
        	let permisos = store.getters.getUser.permisos;
        	let permisos_keys = Object.keys(permisos);

        	for(let i=0; i<permisos_keys.length; i++) {
        		// Vue.prototype.$log.info('permiso '+permisos_keys[i]+': ',permisos[permisos_keys[i]]);
        		if (this.clear_name(app) == this.clear_name(permisos[permisos_keys[i]].aplicacion.nombre)) {
        			for(let a=0; a<permisos_validacion.length; a++) {
        				
        				// Vue.prototype.$log.info('permiso1', permisos_validacion[a]);
        				// Vue.prototype.$log.info('permiso2', permisos[permisos_keys[i]].permiso);

	        			if (permisos[permisos_keys[i]].permiso == permisos_validacion[a] || permisos[permisos_keys[i]].permiso == '*') {
							// Vue.prototype.$log.info('permiso concedido');
	        				permitido = permisos[permisos_keys[i]].acceso == 1;

	        				if (permitido  && visible) {
								// Vue.prototype.$log.info('permiso visible');
								permitido = permisos[permisos_keys[i]].ver_aplicacion == 1;
							}

	        				i=permisos_keys.length;
	        			}
	        		}
        		}
        	}
        }

      	return permitido;
      }

      ,clear_name(name) {
      	if (name) {
        	name = name.toLocaleLowerCase();
        	name = name.split('á').join('a');
        	name = name.split('é').join('e');
        	name = name.split('í').join('i');
        	name = name.split('ó').join('o');
        	name = name.split('ú').join('u');
        	name = name.split('ñ').join('n');
        	name = name.split(' ').join('_');
        }

      	return name;
      }
		}
	}
}

Vue.use(Auth);