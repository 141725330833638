import Vue from 'vue'

const logger =  {
	install(Vue, options) {
		Vue.prototype.$log = {
			log: function() {
				this.showMessage('log', arguments);
			}
			,debug: function() {
				this.showMessage('debug', arguments);
			}
			,info: function() {
				this.showMessage('info', arguments);
			}
			,warning: function() {
				this.showMessage('warning', arguments);
			}
			,error: function() {
				this.showMessage('error', arguments);
			}
			,showMessage: function(type, params) {
				if (process.env.VUE_APP_SHOW_LOGS != 'false')
					switch(type) {
						case 'log':
							console.log.apply(null, params);
							break;
						case 'debug':
							console.info.debug(null, params);
							break;
						case 'info':
							console.info.apply(null, params);
							break;
						case 'warning':
							console.warning.apply(null, params);
							break;
						case 'error':
							console.error.apply(null, params);
							break;
					}
			}
		}
	}
}

Vue.use(logger);