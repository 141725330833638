import Vue from 'vue'
import App from './App.vue'
import store from './store'

import './plugins/load'

new Promise(async (done, reject) => {
	await Vue.prototype.$routeManager.loadAllRotues(done);
})
.then(async (allRoutes) => {
	Vue.config.devtools = process.env.NODE_ENV !== 'production';
	Vue.config.silent = process.env.NODE_ENV === 'production';
	Vue.config.productionTip = process.env.NODE_ENV !== 'production';

	let router = await Vue.prototype.$routeManager.mountRoutes(allRoutes);

	new Vue({
	  router,
	  store,
	  render: h => h(App)
	}).$mount('#app')
})