<template>
	<div id="app">
    <router-view/>
		<notifications group="alert" position="top right"/>
		<notifications group="notifications" position="top center"/>
    <div class="loading"></div>
	</div>
</template>

<script type="text/javascript">
  export default {
    mounted: function() {
      this.$moment.locale('es');
    }
  }
</script>

<style lang="scss">
  html, body, #app {
    // width: 100vw;
    // height: 100vh;
    margin: 0px !important;
    padding: 0px;
  }

  #app {
    overflow-x: hidden;
    .clear {
      width: 100%;
      height: 100%;
    }

    .loading {
      width: 100vw;
      height: 100vh;
      background-color: rgba(76, 76, 76, .3);
      opacity: 1;
      transition: opacity linear 0.1s;
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9899;

      &::before {
        width: 40px;
        height: 40px;
        border: solid 3px #fff;
        border-bottom-color: #0781cd;
        border-radius: 50%;
        content: '';
        opacity: inherit;
        animation: 1s linear infinite spinner;
        will-change: transform;
        transform: translate3d(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }
  }

  @keyframes spinner {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }
  
  // .btn {
  //   font-size: 1em !important;
  //   padding: 0.375rem 1rem !important;
  //   border-radius: 0.125rem;
  // }

  // .btn-warning {
  //   background-color: #fff !important;
  // }
  // .btn-warning:hover {
  //   color: #fff !important;
  //   background-color: #ffc107 !important;
  // }
  // .btn-success {
  //   color: #000 !important;
  //   background-color: #fff !important;
  // }
  // .btn-success:hover {
  //   color: #fff !important;
  //   background-color: #28a745 !important;
  // }
  // .btn-primary {
  //   color: #000 !important;
  //   background-color: #fff !important;
  // }
  // .btn-primary:hover {
  //   color: #fff !important;
  //   background-color: #007bff !important;
  // }
  // .btn-danger {
  //   color: #000 !important;
  //   background-color: #fff !important;
  // }
  // .btn-danger:hover {
  //   color: #fff !important;
  //   background-color: #dc3545 !important;
  // }
  // .btn-info {
  //   color: #000 !important;
  //   background-color: #fff !important;
  // }
  // .btn-info:hover {
  //   color: #fff !important;
  //   background-color: #17a2b8 !important;
  // }
  // .form-control {
  //   font-size: 1em !important;
  //   border-radius: 0.125rem !important;
  // }
</style>
