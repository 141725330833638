import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexSessionStorage = new VuexPersist({
    key: 'constelacion.api.v1',
    storage: window.sessionStorage
});

export default new Vuex.Store({
    state: {
        auth: {
            token: null
            ,refresh: null
            ,exp: 0
            ,usuario: null
        }
        ,menu: []
        ,currentMenu: null
        ,apps: {}
    },
    getters: {
        isAuth: state => {
            return typeof(state.auth) != 'undefined' && typeof(state.auth.token) != 'undefined' && state.auth.token !== null;
        }
        ,getAuth: state => {
            return state.auth;
        }
        ,getUser: state => {
            return state.auth.usuario;
        }
        ,getPermissions: state => {
            return state.auth.usuario.permisos;
        }
        ,getRoles: state => {
            return state.auth.usuario.roles;
        }
        ,getApp: state => {
            return state.auth.usuarios.aplicaciones;
        }
        ,getMenu: state => {
            return state.menu;
        }
        ,getItem: (state) => {
            return state;
        }
    },
    mutations: {
        setCredentials(state, login) {
            if (!state.auth)
                state.auth = {
                    token: null
                    ,refresh: null
                    ,exp: null
                    ,usuario: null
                }

            state.auth.token = login.token;
            state.auth.refresh = login.refreshToken;
            state.auth.exp = login.exp;
            state.auth.usuario = login.user;
        }
        ,refreshCredentials(state, data) {
            // Vue.prototype.$log.info('refreshCredentials', data);
            state.auth.token = data.token;
            state.auth.exp = data.exp;
        }
        ,removeCredentials(state) {
            let keys = Object.keys(state);

            for(let i = 0; i<keys.length; i++) {
                delete state[keys[i]];
            }
        }
        ,setMenu: function(state, menu) {
            state.menu = menu;
        }
        ,setCurrentMenu: function(state, menu) {
            state.currentMenu = menu;
        }
        ,removeMenu: function(state) {
            state.menu = [];
        }
        ,setCurrentMenu: function(state, menu) {
            state.currentMenu = menu;
        }
        ,avatarUpdateStatus: function(state, value) {
            state.avatar = value;
        }
        ,updateIdentity: function(state, value) {
            state.auth.usuario.identidad = value;
        }
        ,updateContacts: function(state, value) {
            state.auth.usuario.contactos = value;
        }
        ,set: function(state, value) {
            Object.assign(state, value);
        }
    },
    actions: {},
    modules: {},
    plugins: [vuexSessionStorage.plugin]
})