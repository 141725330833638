import Vue from 'vue'
import Router from 'vue-router'

var rutas = [];

const routeManager = {
	install(Vue, options) {
		Vue.prototype.$routeManager = {
			addRoute: async function(routes, callback) {
				let all_routes = [];
				for(let i=0; i<routes.length; i++) {
					if (routes[i].default) {
						let tmpRoutes = routes[i].default;
						for(let a=0; a<tmpRoutes.length; a++) {
							await this.checkRoute(a, tmpRoutes[a]);
							await this.checkUnique(tmpRoutes[a]);
						}

						all_routes = all_routes.concat(tmpRoutes);
					}
				}

				callback(all_routes);
			}

			,checkRoute: async function(i, route, child) {
				if (route) {
					if (typeof(route.path) == 'undefined')
						throw new Error('Debes definir el path en la ruta "'+(!child ? i : 'padre '+i+', en el hijo '+child)+'"');

					if (route.children) {
						for(let a=0; a<route.children.length; a++) {
							await this.checkRoute(i, route.children[i], a);
						}
					}else {
						if (!route.name)
							throw new Error('Debes definir el nombre en la ruta "'+(!child ? i : 'padre '+i+', en el hijo '+child)+'"');

						if (!route.component)
							throw new Error('Debes defiir el componenente en la ruta "'+(!child ? i : 'padre '+i+', en el hijo '+child)+'"');
					}
				}
			}

			,checkUnique: async function(route) {
				for await (let item of rutas) {
					if (typeof(route.path) != 'undefined' && route.path == item.path)
						throw new Error('Ya existe una ruta con el path "'+route.path+'"');

					if (route.name == item.name)
						throw new Error('Ya existe una ruta con el nombre "'+route.name+'"');
				}
			}

			,loadAllRotues: async function(callback) {
				let rutas = await import('@/routes');
				await rutas.default.then(async res => {
					this.addRoute(res, callback);
				})
			}

			,mountRoutes: async function(rutas) {
				console.log('rutas', rutas);
				const router = new Router({
					mode: 'history',
					base: process.env.BASE_URL,
					routes: rutas,
				});

				function nextFactory(context, middleware, index) {
					const subsecuent = middleware[index];

					if (!subsecuent)
						return context.next;

					return (...parameters) => {
						context.next(...parameters);

						const nextMiddleware = nextFactory(context, middleware, index+1);
						subsecuent({...context, next: nextMiddleware});
					}
				}

				router.beforeEach((to, from, next) => {
				  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

				  if(nearestWithTitle)
				  	document.title = nearestWithTitle.meta.title + ' - ' + process.env.VUE_APP_TITLE;

				  if (to.meta.middleware) {
				  	const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

				  	const context = {from, next, router, to};

				  	const nextMiddleware = nextFactory(context, middleware, 1);

				  	return middleware[0]({...context, next: nextMiddleware});
				  }

				  next();
				});

				return router;
			}
		}
	}
}

Vue.use(routeManager);