import Vue from 'vue';
import 'bootstrap'

import 'bootstrap/dist/css/bootstrap.css'

Vue.directive('tooltip', function(el, binding) {
  $(el).tooltip({
       title: binding.value,
       placement: binding.arg,
       trigger: 'hover'             
   })
});