import Vue from 'vue'

/** 
 * Validaciones disponibles
 * required
 * required_without:CAMPO_A_VALIDAR
 * money
 * numeric
 * float
 * email
 * phone
 * checked
 * password
 * password_confirm
 * value_greate:VALOR_A_VALIDAR
 * value_greate_or_equal:VALOR_A_VALIDAR
 * value_less:VALOR_A_VALIDAR
 * value_less_or_equal:VALOR_A_VALIDAR
 * date
 * date_before:FECHA_A_VALIDAR
 * date_after:FECHA_A_VALIDAR
 * in:VALORES_A_VALIDAR
 * not_in:VALORES_A_VALIDAR
 * min:NUMERO_DE_CARTACTERES_O_ITEMS_MINIMOS
 * max:NUMERO_DE_CARTACTERES_O_ITEMS_MAXIMOS
 * size:NUMERO_DE_CARTACTERES_O_ITEMS_A_TENER
 */

 const class_validacion = {
  errores: [],

  async obtener_validaciones(config) {
    let campos = Object.keys(config);
    let valido = true;
    this.errores = [];
    for await (let campo of campos) {
      let validaciones = config[campo];
      validaciones = validaciones.split('|');

      this.correr_validaciones(campo, validaciones, campos);
    }

    return this.errores.length == 0;
  },

  correr_validaciones(campo, validaciones, campos) {
    let input = document.querySelector('#'+campo);
    let errores = [];
    
    if (input) {
      validaciones.forEach(validacion => {
        let parametros = null;
        if (validacion.indexOf(':') != -1) {
          let validacion_parametros = validacion.split(':');
          validacion = validacion_parametros[0];
          parametros = validacion_parametros[1].split(',');
        }
        
        switch(validacion) {
          case 'required':
            errores = this.required(input, errores);
            break;
          case 'required_without':
            errores = this.required_without(input, errores, parametros, campos);
            break;
          case 'money':
            errores = this.money(input, errores);
            break;
          case 'numeric':
          case 'float':
            errores = this.numeric(input, errores);
            break;
          case 'email':
            errores = this.email(input, errores);
            break;
          case 'phone':
            errores = this.phone(input, errores);
            break;
          case 'checked':
            errores = this.checked(input, errores);
            break;
          case 'password':
            errores = this.password(input, errores);
            break;
          case 'password_confirm':
            errores = this.password_confirm(input, errores, parametros, campos);
            break;
          case 'value_greate':
            errores = this.value_greate(input, errores, parametros, campos);
            break;
          case 'value_greate_or_equal':
            errores = this.value_greate_or_equal(input, errores, parametros, campos);
            break;
          case 'value_less':
            errores = this.value_less(input, errores, parametros, campos);
            break;
          case 'value_less_or_equal':
            errores = this.value_less_or_equal(input, errores, parametros, campos);
            break;
          case 'date':
            errores = this.date(input, errores, parametros, campos);
            break;
          case 'date_before':
            errores = this.date_before(input, errores, parametros, campos);
            break;
          case 'date_after':
              errores = this.date_after(input, errores, parametros, campos);
              break;
          case 'in':
            errores = this.in(input, errores, parametros, campos);
            break;
          case 'not_in':
            errores = this.not_in(input, errores, parametros, campos);
            break;
          case 'min':
            errores = this.min(input, errores, parametros, campos);
            break;
          case 'max':
            errores = this.max(input, errores, parametros, campos);
            break;
          case 'size':
            errores = this.size(input, errores, parametros, campos);
            break;
        }
      });

      this.addErrors(campo, input, errores);
    }

    this.errores = this.errores.concat(errores);
  },

  required(input, errores) {
    if (input.value == null || input.value == '' || input.value == 'na')
      errores.push('El campo es requierdo');

    return errores;
  },

  required_without(input, errores, parametros, campos) {
    parametros.forEach(param => {
      let valid_input = document.querySelector('#'+param);

      if (!valid_input) {
        if (!input.value || input.value == '' || input.value == 'na') {
          errores.push(`El campo es requierdo`);
        }

        return errores;
      }

      if (!valid_input.value || valid_input.value == '' || valid_input.value == 'na')
        if (!input.value || input.value == '' || input.value == 'na')
          errores.push(`El campo es requierdo`);
    });

    return errores;
  },

  money(input,errores) {
    let value = input.value.replace(/[^\d.]/g,'');

    if (value == 0 || isNaN(value))
      errores.push('El campo debe ser forrmato moneda ($1,000.00)');

    return errores;
  },

  numeric(input, errores) {
    if (isNaN(input.value))
      errores.push('El campo debe ser númerico');

    return errores;
  },

  email(input, errores) {
    if (!String(input.value).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
      errores.push('El campo debe ser un email válido');

    return errores;
  },

  phone(input, errores) {
    let digitos = Math.log(input.value) * Math.LOG10E + 1 | 0;
    if (digitos != 10)
      errores.push('El campo debe ser de 10 dígitos');

    errores = this.numeric(input, errores);

    return errores;
  },

  checked(input, errores) {
    if (input.checked === false)
      errores.push('El campo debe estar marcado');

    return errores;
  },

  password(input, errores) {
    let value = input.value;
    let strength = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;

    if (!strength.test(value))
      errores.push('La contraseña debe tener al menos 8 caracteres, letras y números, letras minúsculas y mayúsculas y al menos un caracter especial');

    return errores;
  },

  password_confirm(input, errores, parametros, campos) {
    let nombre = input.id+'_confirm';

    let input_confirm = document.querySelector('#'+nombre);

    if (!input_confirm) {
      errores.push('No se encontró la confirmación');
      return errores;
    }
    
    if (!input.value || input.value == '')
      errores.push('Debes definir la confirmación de la contraseña');

    if (input.value != input_confirm.value)
      errores.push('La contraseña y la confirmación no son iguales');

    return errores;
  },

  value_greate(input, errores, parametros, campos) {
    let valor = input.value;
    let valor_comparar = parseFloat(parametros[0]);

    if (valor == '' || valor == null || isNaN(valor))
      errores.push('El valor no es númerico');

    if (isNaN(valor_comparar))
      errores.push('El valor a comparar no es númerico');

    if (errores.length == 0 && valor <= valor_comparar)
      errores.push('El valor es menor o igual a '+valor_comparar.toString());

    return errores;
  },

  value_greate_or_equal(input, errores, parametros, campos) {
    let valor = input.value;
    let valor_comparar = parseFloat(parametros[0]);

    if (valor == '' || valor == null || isNaN(valor))
      errores.push('El valor no es númerico');

    if (isNaN(valor_comparar))
      errores.push('El valor a comparar no es númerico');

    if (errores.length == 0 && valor < valor_comparar)
      errores.push('El valor es menor o igual a '+valor_comparar.toString());

    return errores;
  },

  value_less(input, errores, parametros, campos) {
    let valor = input.value;
    let valor_comparar = parseFloat(parametros[0]);

    if (valor == '' || valor == null || isNaN(valor))
      errores.push('El valor no es númerico');

    if (isNaN(valor_comparar))
      errores.push('El valor a comparar no es númerico');

    if (errores.length == 0 && valor >= valor_comparar)
      errores.push('El valor es mayor o igual a '+valor_comparar.toString());

    return errores;
  },

  value_less_or_equal(input, errores, parametros, campos) {
    let valor = input.value;
    let valor_comparar = parseFloat(parametros[0]);

    if (valor == '' || valor == null || isNaN(valor))
      errores.push('El valor no es númerico');

    if (isNaN(valor_comparar))
      errores.push('El valor a comparar no es númerico');

    if (errores.length == 0 && valor > valor_comparar)
      errores.push('El valor es mayor o igual a '+valor_comparar.toString());

    return errores;
  },

  date(input, errores, parametros, campos) {
    let dia = new Date(input.value);

    Date.prototype.isValid = function() {
      return this.getTime() === this.getTime();
    }

    let isValid = false;
    if (Object.prototype.toString.call(dia) === '[object Date]') {
      if (!isNaN(dia))
        isValid = true;
    }

    if (!dia.isValid())
      isValid = false;

    if (!isValid)
      errores.push('La fecha es inválida');

    return errores;
  },

  date_before(input, errores, parametros, campos) {
    let dia = new Date(input.value);
    let dia_anterior = new Date();
    
    if (parametros && parametros.length > 0)
      dia_anterior = new Date(parametros[0]);

    if (dia > dia_anterior) {
      let anio = dia_anterior.getFullYear();
      anio = anio.toString().padStart(2, '0');
      let mes = dia_anterior.getMonth()+1;
      mes = mes.toString().padStart(2, '0');
      let dia = dia_anterior.getDate();
      dia = dia.toString().padStart(2, '0');
      errores.push('La fecha es posterior a '+anio+'/'+mes+'/'+dia);
    }

    return errores;
  },

  date_after(input, errores, parametros, campos) {
    let dia = new Date(input.value);
    let dia_siguiente = new Date();
    
    if (parametros && parametros.length > 0)
      dia_siguiente = new Date(parametros[0]);

    if (dia < dia_siguiente) {
      let anio = dia_siguiente.getFullYear();
      anio = anio.toString().padStart(2, '0');
      let mes = dia_siguiente.getMonth()+1;
      mes = mes.toString().padStart(2, '0');
      let dia = dia_siguiente.getDate();
      dia = dia.toString().padStart(2, '0');
      errores.push('La fecha es anterior a '+anio+'/'+mes+'/'+dia);
    }

    return errores;
  },

  value_greate(input, errores, parametros, campos) {
    let valor = input.value;
    let valor_comparar = parseFloat(parametros[0]);

    if (valor == '' || valor == null || isNaN(valor))
      errores.push('El valor no es númerico');

    if (isNaN(valor_comparar))
      errores.push('El valor a comparar no es númerico');

    if (errores.length == 0 && valor <= valor_comparar)
      errores.push('El valor es menor o igual a '+valor_comparar.toString());

    return errores;
  },

  value_greate_or_equal(input, errores, parametros, campos) {
    let valor = input.value;
    let valor_comparar = parseFloat(parametros[0]);

    if (valor == '' || valor == null || isNaN(valor))
      errores.push('El valor no es númerico');

    if (isNaN(valor_comparar))
      errores.push('El valor a comparar no es númerico');

    if (errores.length == 0 && valor < valor_comparar)
      errores.push('El valor es menor o igual a '+valor_comparar.toString());

    return errores;
  },

  value_less(input, errores, parametros, campos) {
    let valor = input.value;
    let valor_comparar = parseFloat(parametros[0]);

    if (valor == '' || valor == null || isNaN(valor))
      errores.push('El valor no es númerico');

    if (isNaN(valor_comparar))
      errores.push('El valor a comparar no es númerico');

    if (errores.length == 0 && valor >= valor_comparar)
      errores.push('El valor es mayor o igual a '+valor_comparar.toString());

    return errores;
  },

  value_less_or_equal(input, errores, parametros, campos) {
    let valor = input.value;
    let valor_comparar = parseFloat(parametros[0]);

    if (valor == '' || valor == null || isNaN(valor))
      errores.push('El valor no es númerico');

    if (isNaN(valor_comparar))
      errores.push('El valor a comparar no es númerico');

    if (errores.length == 0 && valor > valor_comparar)
      errores.push('El valor es mayor o igual a '+valor_comparar.toString());

    return errores;
  },

  date(input, errores, parametros, campos) {
    let dia = new Date(input.value);

    Date.prototype.isValid = function() {
      return this.getTime() === this.getTime();
    }

    let isValid = false;
    if (Object.prototype.toString.call(dia) === '[object Date]') {
      if (!isNaN(dia))
        isValid = true;
    }

    if (!dia.isValid())
      isValid = false;

    if (!isValid)
      errores.push('La fecha es inválida');

    return errores;
  },

  in(input, errores, parametros, campos) {
    let valor = input.value;
    console.log('parametros', parametros);
    console.log('campos', campos);

    if (parametros.indexOf(valor) == -1)
      errores.push('EL valor debe ser alguno de estos: '+parametros.join(', '))

    return errores;
  },

  not_in(input, errores, parametros, campos) {
    let valor = input.value;
    console.log('parametros', parametros);
    console.log('campos', campos);

    if (parametros.indexOf(valor) != -1)
      errores.push('EL valor no debe ser ninguno de estos: '+parametros.join(', '))

    return errores;
  },

  min(input, errores, parametros, campos) {
    if (input.value.length < parseFloat(parametros[0])) {
      if (typeof input.value == 'string')
        errores.push('El valor debe tener al menos '+parametros[0]+' caracteres');
      else if (typeof input.value == 'number' || typeof input.value == 'float')
        errores.push('El valor debe ser menor a '+parametros[0]);
      else
        errores.push('El valor debe tener mínimo '+parametros[0]+' items');  
    }

    return errores;
  },

  max(input, errores, parametros, campos) {
    if (input.value.length > parseFloat(parametros[0])) {
      if (typeof input.value == 'string')
        errores.push('El valor debe tener a lo mucho '+parametros[0]+' caracteres');
      else
        errores.push('El valor debe tener máximo '+parametros[0]+' items');  
    }

    return errores;
  },

  size(input, errores, parametros, campos) {
    if (input.value.length != parseInt(parametros[0]))
      if (typeof input.value == 'string')
        errores.push('El valor debe tener '+parametros[0]+' caracteres');
      else 
        errores.push('El valor debe tener '+parametros[0]+' items');
    return errores;
  },

  addErrors(campo, input, errores) {
    
    let spanExists = document.querySelector('[data-input="'+campo+'"]');

    if (errores.length > 0) {
      if (spanExists)
        spanExists.parentNode.removeChild(spanExists);

      let error = '';

      errores.forEach(err => {
        error += error.length == 0 ? err : '<br/>'+err;
      })

      let span = document.createElement('div');
      span.innerHTML = error;
      span.dataset.input = campo;
      span.classList.add('bpb-invalid');
      input.parentNode.insertBefore(span, input.nextSibling);
      input.classList.add('bpb-invalid-input');
    }else {
      if (input.classList.contains('bpb-invalid-input'))
        input.classList.remove('bpb-invalid-input');

      if (spanExists && errores.length == 0)
        spanExists.parentNode.removeChild(spanExists);
    }
  }
}

const validator = {
  install(Vue, options) {
    Vue.prototype.$validator = function(config) {
      return class_validacion.obtener_validaciones(config);
    }
  }
}

Vue.use(validator);